@import '../App.css';

div.shopRoot {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;
    width: 100%;
}

span.shoppingDescription {
    width: 60%;
    max-width: 50rem;
    font-size: 1.25rem;
    font-weight: bold;
    margin: 1rem 0;
}

div.productGrid {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fit, calc(var(--productWidth) + var(--productGap) * 2));
    gap: var(--productGap);
    justify-content: center;
}