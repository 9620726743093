div.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 55rem;
  color: #444;
  padding-bottom: 10rem;
}

span.header {
  font-size: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: rgba(175,62,146,1);
}

span.listMessage {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}

div.lineItem {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding-bottom: 2rem;
  justify-items: center;
  color: #444;
  font-size: 1.2rem;
  font-weight: 300;
  align-items: end;
  border-bottom: 1px solid #ddd;
}

span.lineItemKeyLink {
  color: #af3e92;
}

/*
div.lineItem {
  display: flex;
  border: 1px solid #bbb;
  border-radius: 0.5rem;
  margin: 1rem;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  div.lineItem {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-items: center;
    font-size: 0.8rem;
    padding: 0.5rem;
    margin: 0.5rem;  
  }
}

div.lineItemText {
  margin: 0 1rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}

img.lineItem {
  max-width: 4rem;
  max-height: 4rem;
  width: auto;
  height: auto;  
}
*/