.header {
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.75rem;
  overflow: hidden;
  height: 4.5rem;
  background-color: #fba800;
  margin-top: 3rem;
  width: 98%;
  max-width: 76rem;
  z-index: 10;
}

@media screen and (max-width: 992px) {
  .header {
    background-color: #ffedcc;
    margin-top: 0;
    width: 100vw;
    border-radius: 0;

  }
}

/* How to remove the sticky header: */
/* .header position line, delete*/
/* div.breadcrumbs margin-top from 10rem to 2rem */

.headerLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 264px;
  background-color: #fff;
  height: 100%;
}

@media screen and (max-width: 992px) {
  .headerLogo {
    width: 230px;
    background-color: transparent;
    flex-grow: 1;
    justify-content: flex-start;
    margin-left: 1rem;
  }
}


.headerLogo img {
  width: 200px;
}

.navItems {
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media screen and (max-width: 992px) {
  .navItems {
    justify-content:flex-end;
    padding-right: 1rem;
  }
}


.navItem {
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  .navItem:not(.cart) {
      display: none;
  }
}

div.loginButton {
  border: 1px solid #fff;
  padding: 0.1rem 2rem;
  border-radius: 0.25rem;
}

div.loginButtonGlow {
  background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0.5) 100%);
}

.cart {
  position: relative;
  margin-top: 0.2rem;
}

img.cart {
  height: 2rem;
}

span.cartDot {
  position: absolute;
  top: -0.25rem;
  right: -0.5rem;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.9rem;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  border-radius: 1rem;
  background: #f66;
}

img.cartDotSpinnerSpinner {
  height: 100%;
}

div.hamburger {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin-left: 1rem;
}
@media screen and (max-width: 992px) {
  div.hamburger {
      display: flex;
  }
}

div.hamburger > * {
  transition: filter 0.5s;
}

.invertChildren > * {
  filter: invert(1);
}

svg.hamburger {
  position: relative;
  top: 0.25rem;
  height: 1.5rem;
}

.topLine, .middleLine, .bottomLine {
  transition: d 0.5s ease;
}

span.hamburger {
  font-size: 0.5rem;
}

div.mainMenu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #FBA800;
  padding: 20vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  transition: transform 0.5s;
}

span.mainMenu {
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 1.25rem;
}

div.breadcrumbs {
  width: 90%;
  max-width: 70rem;
  font-size: 1.2rem;
  font-weight: bold;
  padding-top: 10rem;
}
@media screen and (max-width: 992px) {
  div.breadcrumbs {
    padding-top: 6rem;
    font-size: 1rem;
  }
}

div.breadcrumbs a:link,
div.breadcrumbs a:visited,
div.breadcrumbs a:hover,
div.breadcrumbs a:active {
  color: #af3e92;
  text-decoration: none;
}