div.footerGrid {
    display: grid;
    grid-template-columns: 20rem 1fr 1fr 1fr 1fr ;
    margin: 2rem auto;
    width: 90%;
    max-width: 70rem;
    min-width: 40rem;
}
@media screen and (max-width: 992px) {
    div.footerGrid {
        grid-template-columns: 1fr;
        min-width: unset;
        width: unset;
    }
}

div.imgWrapper {
    display: flex;
    flex-direction: column;
    width: 18rem;
    padding-right: 2rem;
    border-right: 1px solid #888;
}
@media screen and (max-width: 992px) {
    div.imgWrapper {
        border-right: 0;
        padding-right: 0;
    }
}

img.footerImg {
    width: 18rem;
    margin-bottom: 1rem;
}

span.footerLink {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #888;
}
@media screen and (max-width: 992px) {
    span.footerLink {
        padding-top: 1rem;
        border-right: 0;
    }
}


div.smallPrint {
    width: 90%;
    max-width: 70rem;
    min-width: 40rem;
    text-align: center;

    margin: 2rem auto;
    font-size: 0.8rem;
}
@media screen and (max-width: 992px) {
    div.smallPrint {
        min-width: unset;
    }
}

span.smallPrint {
    margin: 0 1rem;
    white-space: nowrap;
    color: #444;
}

div.footerBlock {
    width: 100%;
    text-align: center;
    background: #fba800;
    color: #fff;
    padding: 3rem 0;
}