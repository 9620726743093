div.contentWrapper {
  display: flex;
  flex-direction: column;
  max-width: 55rem;
  width: 90%;
  line-height: 2rem;
  color: #444;
  padding-bottom: 10rem;
}

span.content {
  margin-bottom: 2rem;
}