:root {
  --radioSize: 1.5rem;
  --checkboxSize: 1.75rem;
}

div.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10rem;
}

div.form {
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  width: 90%;
  padding-bottom: 5rem;
}

span.subheader {
  margin-top: 1rem;
  font-size: 1.25rem;
}

span.red {
  color: #b33;
  margin: 0 1rem;
}

div.doubleInput {
  display: flex;
  justify-content: space-between;
}

input[type="email"].signup,
input[type="text"].signup,
input[type="password"].signup {
  flex: 1;
  font-size: 1.25rem;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: 1px solid #888;
  transition: background-color 0.5s;
}

div.doubleInput > input[type="text"] {
  max-width: calc(50% - 1rem);
}


.genderOptions {
  display: flex;
  font-size: var(--radioSize);
  margin-bottom: 3rem;
}

.genderOptions label {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 3rem;
}

.genderOptions input[type="radio"] {
  display: none; /* Hide the default radio button */
}

.radioCircle {
  width: var(--radioSize);
  height: var(--radioSize);
  border-radius: 50%;
  border: 2px solid #000;
  background-color: transparent;
}

.genderOptions input[type="radio"]:checked + .radioCircle {
  background: radial-gradient(circle at center, #2f80ed 0%, #2f80ed 35%, transparent 45%, transparent 100%);
}

input[type="date"] {
  position: relative;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.5rem;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  border-radius: 0.5rem;
  border: 1px solid #888;
  color: #444;
  flex: 1;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}

span.duplicateEmailError {
  color: #b33;
}

span.passwordInfo {
  font-weight: bold;
}


button.register {
  width: 30rem;
  color: #fff;
  background-color: #fba800;
  border: 1px solid transparent;
  transition: background-color 0.25s;
}

button.register:hover {
  color: #fba800;
  background-color: transparent;
  border: 1px solid #fba800;
}

button.processing {
  color: transparent;
  background: #fba800 url(../../public/spinner.svg);
  background-size: 3rem;
  background-position: center center;
  background-repeat: no-repeat;
}

div.success {
  position: fixed;
  display: flex;
  display: none;
  justify-content: center;
  top:50%;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(136, 187, 68, 0.5) 0%, #8b4 25%, #8b4 75%, rgba(136, 187, 68, 0.5) 100%);
  color: #fff;
  font-size: 3rem;
  padding: 2rem 0;
}

.inputError {
  background-color: #fbb;
}
