:root {
  --productWidth: 300px;
  --productGap: 20px;
  --logoPadding: 15px;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

body.no-scroll {
  overflow: hidden;
}

.App {
  text-align: center;
}

.no-style {
  all: unset;
}

a {
  text-decoration: none;
  color: unset;
}

div.topDots {
  background-image: url(../public/dots_purple.svg);
  height: 300px;
  width: 100%;
  position: absolute;
  background-repeat: repeat-x;
  z-index: -1;
  margin-top: -25px;
}
@media screen and (max-width: 992px) {
  div.topDots {
      display: none;
  }
}

.topHeader {
  font-size: 4rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: 70%;
  max-width: 50rem;
  color: #af3e92;
  border-bottom: 1rem solid #fff6e5;
  margin-top: 5rem;
  margin-bottom: 3rem;
}
@media screen and (max-width: 768px) {
  .topHeader {
    font-size: 2rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    }
}

button {
  background-color: #fba800;
  color: #fff;
  width: 25rem;
  height: 4.5rem;
  margin: 2rem 0;
  padding: 1rem;
  border: 1px solid #fba800;
  border-radius: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.25s;
}

@media screen and (max-width: 992px) {
  button {
    max-width: 20rem;
    width: calc(100vw - 4rem);
    font-size: 1rem;
  }
}

button:hover {
  background-color: transparent;
  color: #fba800;
  border: 1px solid #fba800;
}

div.customCheckbox {
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  color: #444;
}

label.customCheckbox {
  display: flex;
  align-items: center;
  gap: 1rem;
}

div.customCheckbox input[type="checkbox"] {
  display: none; /* Hide the default checkbox */
}

span.customCheckbox {
  font-size: var(--checkboxSize);
  color: transparent;
  display: inline-block;
  width: var(--checkboxSize);
  height: var(--checkboxSize);
  border: 1px solid #000;
  border-radius: 0.25rem;
  text-align: center;
  line-height: var(--checkboxSize);
  cursor: pointer;
  transition: background-color 0.25s;
}


div.customCheckbox input[type="checkbox"]:checked + span.customCheckbox {
  background-color: #2f80ed;
  color: #fff;
}

input[type="text"].formField,
input[type="password"],
select.formField {
  display: flex;
  width: 100%;
  margin: 1rem 0;
  font-size: 1.25rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #888;
  box-sizing: border-box;
}

#payment-element {
  width: 90% !important;
}
