div.productRoot {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}

div.overlay {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
    z-index: 20;
}

div.modal {
    position: relative;
    top: 12rem;
    padding: 2rem 4rem;
    width: 24rem;
    height: 12rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
}

span.modal {
    font-size: 1.25rem;
    padding-bottom: 3rem;
}

button.modal {
    margin: 0;
}

div.productGrid {
    display: grid;
    width: 90%;
    max-width: 80rem;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 4rem;
}
@media screen and (max-width: 768px) {
    div.productGrid {
        grid-template-columns: 1fr;
    }
}
  

div.imageGrid{
    display: grid;
    align-self: flex-start;
    width: 100%;
    grid-template-columns: 50% 50%;
}

img.productImage {
    width: 100%;
}

div.productContent {
    display: flex;
    flex-direction: column;
}

span.productDescription {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

span.productPriceWithoutTax {
    font-size: 1.5rem;
    font-weight: bold;
}

span.productPriceWithoutTaxStrikeout {
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: line-through;
    margin-right: 1rem;
}

span.productPriceWithTax {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #444;
}

span.productPriceWithTaxStrikeout {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #444;
    text-decoration: line-through;
    margin-right: 1rem;
}

div.quantityComponent {
    margin: 1rem 0;
    padding: 0;
    border-radius: 1rem;
    border: 1px solid #000;
    display: flex;
    align-self: flex-start;
}

button.quantityButton {
    border: 0;
    background: transparent;
    font-size: 2.5rem;
    padding: 0 1rem;
    margin: 0;
    width: unset;
    color: #000;
}

button.quantityButton:hover {
    border: 0;
    color: #000;
}

input[type="text"].quantityInput {
    border: 0;
    font-size: 2rem;
    margin: 0 1rem;
}

span.quantityInfo {
    font-size: 0.8rem;
}

button.addToCart {
    background-color: #fba800;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 0.5rem;
}

button.viewCart {
    background-color: #fb4600;
}

img.spinner {
  height: 2rem;
  filter: invert(100%);
}


span.productLongDescription {
    font-size: 1.2rem;
}

div.productFaq {
    margin: 2rem 0;
}

div.productFaq > details > summary {
    display: flex;
    position: relative;
    cursor: pointer;
    padding: 1rem;
    border-top: 1px solid #fba800;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
}

div.productFaq > details > summary::before {
    content: '›';   /* This is a right-pointing chevron */
    position: absolute;
    right: 20px;    /* Adjust as needed */
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;  /* Smooth transition for rotation */
}

div.productFaq > details[open] summary::before {
    transform: rotate(-90deg);  /* Rotate to point upwards */
}

div.productFaq > details > p {
    font-size: 1.2rem;
    margin-top: 0;
}

div.infoLinks {
    display: flex;
    width: 80%;
    max-width: 80rem;
    margin: 3rem 0;
    justify-content: space-evenly;
}
@media screen and (max-width: 768px) {
    div.infoLinks {
        flex-direction: column;
        align-items: center;
    }
}

button.infoLink {
    font-size: 1.5rem;
    font-weight: bold;
    width: 18rem;
    padding: 1rem 0;
    border-radius: 0;
    background-color: transparent;
    border: 1px solid #bbb;
    color: #af3e92;
    transition: background-color 0.3s, color 0.3s;
}
@media screen and (max-width: 992px) {
    button.infoLink {
        font-size: 1rem;
        margin: 1rem;
    }
}  

button.infoLink:hover {
    background-color: #af3e92;
    color: #ffffff;
}

span.otherHeader {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fba800;
}

div.otherProductsGrid {
    display: grid;
    justify-items: center;
    width: 90%;
    max-width: 80rem;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 2rem 0;
}
@media screen and (max-width: 768px) {
    div.otherProductsGrid {
        grid-template-columns: 1fr;
    }
}
