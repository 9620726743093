div.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 20;
}

div.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 30rem;
  max-width: 90%;
  height: 25rem;
  max-height: 90%;
  background-color: #fff;
  border-radius: 1.5rem;
  box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.1);
  border: 1px solid #888;
}

img.modalImg {
  height: 2rem;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 4rem;
  background: linear-gradient(#eee, #fff);
  box-shadow: 0.1rem 0.1rem 1rem -0.25rem rgba(0,0,0,.1);
}

span.modalSpan {
  font-size: 1.5rem;
  text-align: center;
}

div.modalLinks {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

span.modalLinkPrimary {
  border: 2px solid #fba800;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1.25rem;
  background: #fba800;
  color: #fff;
  font-weight: bold;
}

span.modalLinkSecondary {
  border: 2px solid #fba800;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: #fba800;
  font-size: 1.25rem;
  font-weight: bold;
}


div.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  width: 90%;
  padding-bottom: 10rem;
}

span.subHeader1 {
  margin: 0.5rem 0;
}

span.subHeader2 {
  margin-bottom: 1.5rem;
}

span.inputHeader {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  font-weight: bold;
}

span.required {
  color: #f44;
  margin: 0 0.5rem;
}

.checkboxTextLink {
  color: #006;
  text-decoration: underline;
}

span.checkboxFooter {
  font-size: 0.8rem;
}

span.goodReply {
  color: #020;
}

span.badReply {
  color: #200;
}

div.unknownId {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}

span.unknownId {
  color: #b00;
  font-size: 1.25rem;
}

span.unknownIdSmallPrint {
  font-size: 0.8rem;
  color: #222;
}


div.inputWrapper {
  width: 100%;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
}

span.inputX {
  position: absolute;
  right: 1.5rem;
  border: 1px solid #800;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  background: #fff;
  color: #800;
  font-size: 0.8rem;
}