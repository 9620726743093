div.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60rem;
  max-width: 90%;
}

span.header {
  font-size: 2.5rem;
  font-weight: bold;
  color: #af3e92;
}
@media screen and (max-width: 992px) {
  span.header {
    font-size: 1.5rem;
  }
}

div.linksGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin: 3rem 0;
}
/* Between 769px and 992px */
@media screen and (min-width: 769px) and (max-width: 992px) {
  div.linksGrid {
    grid-template-columns: 1fr 1fr;
  }
}

/* 993px and above */
@media screen and (min-width: 993px) {
  div.linksGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


span.link {
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 1.5rem 0;
  border: 1px solid #888;
  font-size: 1.5rem;
  font-weight: bold;
  color: #af3e92;
  transition: background-color 0.25s;
}
@media screen and (max-width: 768px) {
  span.link {
    font-size: 1rem;
  }
}

span.link:hover {
  color: #fff;
  background-color: #af3e92;
  border: 1px solid #af3e92;
}

span.suggestSpan {
  display: flex;
  justify-content: center;
  background: #fba800;
  padding: 0.25rem;
  border-radius: 0 0 1rem 1rem;
}

div.advice {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 1.5rem;
  font-size: 0.8rem;
  color: #444;
}