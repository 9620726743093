div.checkoutWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.3);
  backdrop-filter: blur(5px);
  z-index: 20;
  overflow-y: auto;
}

@media screen and (max-width: 992px) {
  div.checkoutWrapper {
    align-items: unset;
  }
}
