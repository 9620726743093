div.loginWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  width: 90%;
  margin-bottom: 8rem;
}

span.loginSubheader {
  margin-top: 2rem;
  font-size: 1.25rem;
  width: 100%;
}

input.loginInput,
input[type="email"],
input[type="password"] {
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 0.5rem;
  border: 1px solid #333;
  font-size: 1.25rem;
  width: 100%;
  box-sizing: border-box;
}

span.forgotPassword {
  cursor: pointer;
  font-size: 1rem;
  text-decoration: underline;
  width: 100%;
  font-weight: bold;
  color: #444;
}

span.errorMessage {
  position: relative;
  top: -2rem;
  color: #800;
  font-weight: bold;
}

div.loginLine {
  height: 1px;
  width: 40rem;
  background-color: #888;
}

button.loginSignup {
  color: #af3e92;
  background-color: transparent;
  border: 1px solid #af3e92;
}