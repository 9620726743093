@import '../App.css';

div.product {
    display: flex;
    flex-direction: column;
    border: 1px solid #fba800;
    padding: var(--productGap);
    width: var(--productWidth);
}

img.productImage {
    width: 100%;
}

span.productDescription {
    color: #413a3a;
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

span.productPriceNoTax {
    color: #413a3a;
    font-size: 1.3rem;
    font-weight: bold;
}

span.productPriceNoTaxStrikeout {
    color: #413a3a;
    font-size: 1.3rem;
    font-weight: bold;
    text-decoration: line-through;
    margin-right: 1rem;
}

span.productPriceWithTax {
    color: #5c5352;
    font-size: 1rem;
    font-weight: bold;
}

span.productPriceWithTaxStrikeout {
    color: #5c5352;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: line-through;
    margin-right: 1rem;
}