div.newAddressWrapper {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.3);
  backdrop-filter: blur(5px);
  z-index: 20;
  justify-content: center;
  align-items: center;
}

div.newAddressContent {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 1rem;
  max-height: calc(100% - 6rem);
  max-width: 60rem;
  width: 80%;
  overflow-y: auto;
  z-index: 20;
}
@media screen and (max-width: 992px) {
  div.newAddressContent {
    width: calc(100% - 2rem);
    max-height: calc(100% - 2rem);
  }
}


/* Hide scrollbar for Chrome, Safari and Opera */
.newAddressContent::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.newAddressContent {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.newAddressX {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  color: #555;
  background-color: #fff;
  border-radius: 50%; 
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
  transition: all 0.3s; 
}

.newAddressX:hover {
  color: #fff;
  background-color: #f56;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

form.newAddressForm {
  display: flex;
  flex-direction: column;
}

div.inputRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

div.inputsRow {
  display: flex;
}

span.subheader {
  margin-top: 1rem;
  margin-left: 0.5rem;
  font-size: 1rem;
  color: #444;
  font-weight: bold;
}

.red {
  color: #b33;
  margin: 0 1rem;
}

div.labeledInput {
  display: grid;
  grid-template-columns: 50% 50%;
}
@media screen and (max-width: 992px) {
  div.labeledInput {
    grid-template-columns: 1fr 8rem;
  }
}


span.inputLabel {
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
  font-weight: bold;
  color: #444;
}

button.newAddress {
  align-self: center;
}

span.errorMessage {
  text-align: center;
  color: #800;
  font-weight: bold;
  position: relative;
  top: -1.5rem;
}

span.cancelNewAddress {
  text-align: center;
  margin-bottom: 2rem;
  text-decoration: underline;
}




/* Shimmer animation */
@keyframes shimmer {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}

/* Apply the shimmer animation to the input or select field */
.shimmering {
  background: linear-gradient(90deg,#ddd,#fff,#ddd);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
}