div.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 55rem;
  color: #444;
  padding-bottom: 10rem;
  font-size: 1.25rem;
  font-weight: 400;
  align-items: center;
}

div.passwordForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  margin-top: 4rem;
}

button.requestPassword {
  align-self: center;
}

span.requested {
  align-self: center;
}