div.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 45rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: #444;
  padding-bottom: 10rem;
}

span.header {
  font-size: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: rgba(175,62,146,1);
  text-align: center;
}
@media screen and (max-width: 992px) {
  span.header {
    font-size: 1.5rem;
  }
}

div.registerMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fdf2d8;
  width: 100%;
  padding-top: 2rem;
}

span.listMessage {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}

div.addressBox {
  background-color: rgba(253,242,216,1);
  display: flex;
  flex-direction: column;
  width: 24rem;
  max-width: 90%;
  padding: 2rem;
  margin: 0.5rem 0;
}
@media screen and (max-width: 992px) {
  div.addressBox {
    padding-bottom: 0;
  }
}


div.addressActions {
  text-align: center;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 992px) {
  div.addressActions {
    flex-direction: column;
    align-items: center;
  }
}


span.addressAction {
  margin: 1rem;
  border: 1px solid #444;
  padding: 0.5rem;
  border-radius: 0.2rem;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  width: 6rem;
}

span.deleteAddress {
  color: #b33;
  font-weight: bold;
}

div.otherAddresses {
  width: 28rem;
  border-top: 1px solid #444;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding-top: 1rem;
}