div.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 45rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: #444;
  padding-bottom: 10rem;
}
@media screen and (max-width: 768px) {
  div.contentWrapper {
    font-size: 1rem;
  }
}

span.content {
  line-height: 2.5rem;
}


img.main {
  width: 100%;
  margin: 1rem 0;
}

.desktop524Only{
  display: unset;
}
@media screen and (max-width: 524px) {
  .desktop524Only {
    display: none;
  }
}

.mobile524Only{
  display: none;
}
@media screen and (max-width: 524px) {
  .mobile524Only {
    display: unset;
  }
}


span.header {
  font-size: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: rgba(175,62,146,1);
}
@media screen and (max-width: 768px) {
  span.header {
    font-size: 1.5rem;
  }
}

span.red {
  color: #b33;
  font-weight: normal;
}

div.step {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
}
@media screen and (max-width: 424px) {
  div.step {
    flex-direction: column;
  }
}


span.stepNumber {
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  height: 3rem;
  background-color: #fba800;
  color: #fff;
  font-size: 2.5rem;

  display: flex;
  justify-content: center;
}

div.imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 40%;
}

img.step {
  display: unset;
}

div.stepText {
  width: 60%;
  min-width: 60%;
  padding: 0 3rem;
  box-sizing: border-box;
}
@media screen and (max-width: 424px) {
  div.stepText {
    padding: 0;
    width: auto;
  }
}


span.stepMain {
  display: flex;
  line-height: 2.5rem;
  margin-bottom: 2rem;
}

span.stepSmall {
  font-size: 1rem;
  font-weight: normal;
}

div.stepTable {
  width: 15rem;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 3rem 3rem;
  font-size: 0.9rem;
  border: 1px solid #888;
}

span.table {
  display: flex;
  background-color: #fff;
  border: 1px solid #888;
  align-items: center;
  justify-content: center;
  text-align: center;
}