div.contentWrapper {
  width: 90%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 4rem;
  color: #444;
}

div.registerMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fdf2d8;
  width: 100%;
  padding-top: 2rem;
}
