span.loading {
  display: flex;
  margin: 3rem 0;
  text-align: center;
  font-size: 1.5rem;
}

div.cartWrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 60rem;
  margin-bottom: 8rem;
}

div.headings {
  display: grid;
  width: 100%;
  grid-template-columns: 60% 25% 15%;
  padding: 1rem 0;
  margin: 1rem 0;
  border-bottom: 1px solid #000;
}
@media screen and (max-width: 992px) {
  div.headings {
    display: none;
  }
}


div.lineItem {
  display: grid;
  grid-template-columns: 60% 25% 15%;
  margin: 2rem 0;
  padding-bottom: 3rem;
  border-bottom: 1px solid #bbb;
}
@media screen and (max-width: 992px) {
  div.lineItem {
    display: flex;
    flex-direction: column;

  }
}

div.lineItemLeft {
  display: flex;
}
@media screen and (max-width: 992px) {
  div.lineItemLeft {
    display: grid;
    grid-template-columns: 30% 70%;
  }
}

img.lineItem {
  width: 100px;
}
@media screen and (max-width: 992px) {
  img.lineItem {
    width: 100%;
    align-self: center;
  }
}

div.description {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}
@media screen and (max-width: 992px) {
  div.description {
    padding-left: 1rem;
  }
}

span.title {
  color: #333;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

span.descriptionPrice {
  color: #333;
  font-weight: bold;
}

div.quantityWrapper {
  display: flex;
  align-items: center;
  max-width: 14rem;
}
@media screen and (max-width: 992px) {
  div.quantityWrapper {
    width: 70%;
    justify-self: flex-end;
    margin: 1rem 0;
    align-self: flex-end;
  }
}


div.quantityChanger {
  display: flex;
  overflow: hidden;
  margin-right: 0.5rem;
  height: 3rem;
  border: 1px solid #000;
  border-radius: 0.5rem;
  flex-grow: 1;
}

span.quantityButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 100%;
  font-size: 2rem;
  background-color: #fff;
}

span.quantityButtonDisabled {
  filter: invert(40%) brightness(150%);
}

img.quantityImg {
  width: 1.5rem;
  height: 1.5rem;
}

span.quantityValue {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  flex-grow: 1;
  min-width: 3rem;
}

img.quantitySpinner {
  filter: invert(0.25);
  position: absolute;
  max-height: 100%;
}

span.quantityTrash {
  font-size: 1.5rem;
  width: 3rem;
  cursor: pointer;
}

span.lineCost {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-size: 1.5rem;
  font-weight: bold;
  color: #666;
}

span.splitToggle {
  grid-column: span 2;
  display: flex;
  justify-content: flex-end;
  padding-right: 3.5rem;
  text-decoration: underline;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  span.splitToggle {
    padding-right: 0;
  }
}

div.addressLines {
  grid-column: span 2;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

div.addressLine {
  display: flex;
}
@media screen and (max-width: 768px) {
  div.addressLine {
    flex-direction: column;
    border-bottom: 1px solid #bbb;
    border-top: 1px solid #bbb;
  }
}


img.spinner {
  height: 2rem;
}

span.addAddress {
  font-size: 2rem;
  width: 2.75rem;
  display: flex;
  margin-top: 1rem;
  border: 1px solid #444;
  border-radius: 0.5rem;
  margin-right: 0.75rem;
  justify-content: center;
  height: 2.5rem;
  align-items: center;
  padding-bottom: 0.25rem;
}

span.subTotal {
  display: flex;
  justify-content: flex-end;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
}

span.subTotalValue {
  margin-left: 1rem;
}

button.checkout {
  margin-left: auto;
  height: 4.5rem;
}

div.requestMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

span.requestMessage {
  font-weight: bold;
  font-size: 1.25rem;
  color: #666;
  margin-top: 2rem;
}

span.errorMessage {
  text-align: center;
  width: 25rem;
  margin-left: auto;
}

span.closeCheckout {
  position: absolute;
}