div.checkoutModal {
  position: relative;
  margin: 3rem;
  padding: 3rem;
  background-color: #fff;
  border-radius: 1rem;
  max-width: 60rem;
  overflow-y: auto;
}
@media screen and (max-width: 992px) {
  div.checkoutModal {
    margin: 0.5rem;
    padding: 0.5rem;
  }
}

.checkoutX {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  color: #555;
  background-color: #fff;
  border-radius: 50%; 
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
  transition: all 0.3s; 
}

.checkoutX:hover {
  color: #fff;
  background-color: #f56;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

div.checkoutFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.checkoutFormContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
}
@media screen and (max-width: 992px) {
  div.checkoutFormContent {
    grid-template-columns: 1fr;
  }
}

form.paymentForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.email {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 1rem 0;
}

span.email {
  color: #444;
}

input.email {
  border-color: #bbb;
  margin: 0;
  font-size: 1rem;
  color: #444;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

input.emailError {
  background-color: #fdd;
}

input.checkboxGlow {
  box-shadow: 0px 0px 10px 5px rgba(128,0,0,0.5);
}

img.spinner {
  height: 2rem;
  filter: invert(1);
}

div.paymentMessage {
  text-align: center;
  font-weight: bold;
  color: #633;
  position: relative;
  top: -1rem;
}

span.checkoutHeader {
  font-size: 1.5rem;
  width: 100%;
  margin: 2rem 0;
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.15);
}

div.checkoutLines {
  border-bottom: 0px solid #ddd;
}

div.checkoutLine {
  display: grid;
  grid-template-columns: 4rem 16rem 4rem 6rem;
  max-width: 30rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 992px) {
  div.checkoutLine {
    grid-template-columns: 4rem 4fr 1fr 2fr;
  }
}


img.checkoutLine {
  max-width: 4rem;
  max-height: 4rem;
  width: auto;
  height: auto;
}

div.lineText {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  gap: 0.25rem;
}

span.lineDescription {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: bold;
  color: #444;
}

span.lineUnitCost {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: bold;
  color: #444;
}

span.lineQuantity {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

span.lineCost {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

div.checkoutSummary {
  margin-left: 5rem;
}

div.checkoutTotals {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  border-top: 1px solid #444;
}

div.checkoutTotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

span.couponMessage {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 2rem;
  font-size: 0.8rem;
}

div.couponDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

input.couponInput {
  width: 8rem;
  height: 1.5rem;
  font-size: 0.9rem;
  font-weight: lighter;
  padding: 0.75rem 0.5rem;
}

button.couponButton {
  height: 1.5rem;
  padding: 0;
  font-size: 0.9rem;
  width: 5rem;
}

span.defaultAddressHeader {
  position: relative;
  top: 1rem;
  color: #400;
  box-shadow: 0 4px 4px -2px rgba(0,0,0,.15);
  padding: 0 1rem;
}

span.billingAddress {
  display: flex;
  justify-content: center;
  background-color: #fef8ec;
  border-bottom: 1px solid #fade9e;
  margin-top: 2rem;
}

div.addressCard {
  background-color: #fdf2d8;
  color: #444;
  font-weight: 700;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

div.clickableAddress {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  cursor: pointer;
}

div.addressActions {
  display: flex;
  justify-content: space-evenly;
  background-color: #fef8ec;
  border-top: 1px solid #fade9e;
}

span.addressAction {
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 1rem 0;
  padding: 0.5rem;
  width: 8rem;
  background-color: #fff;
  border: 1px solid #444;
  border-radius: 0.2rem;
  font-size: 0.8rem;
  text-align: center;
  cursor: pointer;
}
@media screen and (max-width: 992px) {
  span.addressAction {
    width: auto;
  }
}

div.selectAddressModal {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(5px);
  z-index: 40;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.5);
}

div.selectAddressContent {
  background: #fff;
  padding: 3rem;
  border-radius: 2rem;
}

span.selectAddressHeader {
  box-shadow: 0 4px 4px -2px rgba(0,0,0,.15);
  font-size: 1.5rem;
  margin: 2rem 0;
  width: 100%;
}