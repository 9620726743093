div.content {
  display: flex;
  width: 90%;
  max-width: 60rem;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5rem;
}

div.topicSelectors {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
@media screen and (max-width: 992px) {
  div.topicSelectors {
    align-items: unset;
    flex-direction: column;
  }
}


span.currentQuestionTopic {
  background-color: aquamarine;
}

span.topicSelector {
  color: #af3e92;
  font-size: 1.25rem;
  font-weight: bold;
  border: 1px solid #af3e92;
  border-radius: 3rem;
  margin: 0.5rem 1rem;
  padding: 0.5rem;
  width: 14rem;
  display: flex;
  justify-content: center;
}

span.currentTopicSelector {
  color: #fff;
  background-color: #af3e92;
}

details {
  width: 90%;
  margin: 1rem;
}

summary {
  background: #fba800;
  padding: 1rem;
  border-radius: 1rem;
  color: #fff;
  list-style-type: none;
  font-weight: bold;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

span.answer {
  font-size: 1.25rem;
  border: 1px solid #fba800;
  border-radius: 1rem;
  margin-top: 0.5rem;
  padding: 1rem;
}